import React from "react";
import styled from "styled-components";

export default function Floww() {
  return (
    <Wrapper>
      <div className="container">
        <div className="logo">
          <img src="/images/floww.svg" alt="" />
        </div>
        <div className="text">
          <h1>floww is a future of working</h1>
          <p>
            Many companies from Enterprises to SMEs are using floww to reduce
            operational costs. Our solution makes working time to be less, less
            paperwork and bring their staffs’ to work on more valuable tasks.
            Find out more details about floww and contact our team to assist
            you.
          </p>
          <a href="https://flowwsquare.com" target="_blank" rel="noreferrer">
            Discover this powerful
            <span class="material-icons">chevron_right</span>
          </a>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: linear-gradient(40.78deg, #529bb8 0%, #88cde7 99.67%);
  padding: 5rem 2rem;
  color: #ffffff;
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .text {
    grid-area: text;
    h1 {
      font-weight: 500;
      @media screen and (max-width: 767px) {
        font-size: 2.8rem;
      }
      font-size: 4rem;
      margin-bottom: 2rem;
    }
    p {
      line-height: 2.4rem;
      margin-bottom: 2.8rem;
    }
    a {
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 40px;
      padding: 1rem 2rem;
      display: inline-block;
      color: #ffffff;
      span {
        transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
        padding-left: 1rem;
        vertical-align: middle;
      }
    }
    a:hover span {
      transform: translateX(5px);
    }
  }
  .logo {
    grid-area: logo;
    padding: 0 0 4rem 0;
    text-align: center;
    /* margin-left: auto;
    margin-right: auto; */
    @media screen and (max-width: 767px) {
      width: 160px;
    }
    img {
      max-width: 100%;
    }
    p {
      margin-top: 12px;
      color: #ffffff;
      opacity: 0.5;
    }
  }

  @media screen and (min-width: 768px) {
    .container {
      display: grid;
      grid-template-areas: "text logo";
      grid-template-columns: 2fr 1fr;
      .text {
        border-right: 1px solid #ffffff;
        padding-right: 20px;
        margin-right: 20px;
      }
      .logo {
        display: grid;
        justify-content: space-around;
        padding: 4rem 0;
      }
    }
  }
`;
