import React from 'react'
import { Helmet } from 'react-helmet'

export default function SiteSeo({ title }) {
  return (
    <Helmet
      title={title || 'Pi R SQUARE Limited | We Draw a Circle by Formula.'}
    >
      <html lang="en" amp />
      <meta charset="utf-8" />
      <meta property="og:url" content="https://www.pirsquare.net" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://pirsquare.net/pir-facebook.png" />
      <meta name="viewport" id="viewport" />
      <meta name="description" content="Pi R SQUARE is a Creative Software House. We produce website, application and game with inspiration. With our Innovative Technology can transform your business and blend in with real user experience." />
      <meta name="keywords" content="creative, mobile application, game creator, สร้างเกม, เกมออนไลน์, โปรแกรมแอนดรอย, แอป, ซอฟแวร์ outsourcing, software house, planner, designer, developer, pir square, pi r square, ar game, ไพ อาร์ สแควร์, บริษัททำเกม, บริษัทซอฟต์แวร์" />
      <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16" />
    </Helmet>
  )
}


