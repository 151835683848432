import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Sticky from 'react-sticky-el';

export default function Header({ isMenuOpen, setIsMenuOpen, setIsTaskToUs, setIsOpeningPosition }) {
  
  const TalkToUsHandler = function () {
    setIsMenuOpen(false)
    setIsTaskToUs(true)
  }
  const WorkWithUsHandler = function () {
    setIsMenuOpen(false)
    setIsOpeningPosition(true)
  }
  
  return (
    <HeaderWrapper>
      <Helmet>
        <body className={isMenuOpen ? "lock" : ""} />
      </Helmet>
      <Sticky stickyClassName={"blue-bg"}>
        <header>
          <div className="logo">
            <AnchorLink to='/'>
              <img src="/images/logo-main.svg" alt="" className="main"/>
              <img src="/images/logo-white.svg" alt="" className="white"/>
            </AnchorLink>
          </div>

          <div className="navigation">
            <span className={isMenuOpen ? "open circle-bg" : "close circle-bg"}></span>
            <div className="toggle-menu" isMenuOpen={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)} role="button" tabIndex={0} onKeyDown={() => setIsMenuOpen(!isMenuOpen)}>
              <img src="/images/menu.svg" alt=""/>
            </div>
            <ul className={isMenuOpen ? "open" : "close"}>
              <li>
                <AnchorLink to='/#about-us' onClick={() => setIsMenuOpen(false)}>About Us</AnchorLink> 
              </li>
              <li>
                <AnchorLink to='/#what-we-do' onClick={() => setIsMenuOpen(false)}>What we do</AnchorLink>
              </li>
              <li>
                {/* <AnchorLink offset={() => 100} href='#clients' onClick={() => setIsMenuOpen(false)}>Clients</AnchorLink> */}
                <AnchorLink to='/#clients' onClick={() => setIsMenuOpen(false)}>Clients</AnchorLink>
              </li>
              <li className="work-with-us">
                <button onClick={() => WorkWithUsHandler()}>Work with Us</button>
              </li>
              <li className="talk-to-us">
                <button onClick={() => TalkToUsHandler()}>Talk to Us</button>
              </li>
              <li className="social">
                <a href="https://www.facebook.com/pir2thailand/" target="_blank" rel="noreferrer" onClick={() => setIsMenuOpen(false)}  onKeyDown={() => setIsMenuOpen(false)}><img src="/images/facebook.svg" alt="Pi R SQUARE Facebook"å/></a>
              </li>
              <li className="tagline">
                We draw circle by formula
              </li>
            </ul>
          </div>
        </header>
      </Sticky>
    </HeaderWrapper>
  )
}


const HeaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  /* background-color: #FFFFFF; */
  z-index: 9900;
  .blue-bg header {
    padding: 6px 20px;
    background-color: #324B64;
    .logo {
      img {
        max-height: 20px;
      }
      .main {
        display: none;
      }
      .white {
        display: inline-block;
      }
    }
    @media screen and (min-width: 768px) {
      li a, li button {
        color: #FFFFFF !important;
        padding: 0px 10px !important;
      }
      .talk-to-us button {
        border: none !important;
        padding: 0px 0px !important;
      }
      .talk-to-us:hover button {
        background-color: transparent !important;
      }
    }
  }
  header {
    padding: 10px 20px;
    background-color: transparent;
    transition: 0.2s;

    .logo {
      .main {
        display: inline-block;
      }
      .white {
        display: none;
      }
    }
    
    @media screen and (max-width: 767px) {
      padding: 10px 10px;  
      .logo {
        img {
          max-height: 40px;
        }
      }
      .navigation .toggle-menu {
        img {
          width: 16px;
        }
        z-index: 99980;
        position: absolute;
        right: 17.5px;
        top: 12px;
      }
      .navigation ul.open {
        max-height: 100vh;
        opacity: 1;
      }
      .navigation ul.close {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
      }
      .navigation ul {
        transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        margin-top: 60px;
      }

      .navigation span.circle-bg.open {
        transform: scale(35)
      }
      .navigation span.circle-bg.close {
        transform: scale(1)
      }
      .navigation span.circle-bg {
        transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
        background: #324B64;
        transform-origin: center center;
        height: 30px;
        width: 30px;
        clip-path: circle(50% at 50% 50%);
        position: absolute;
        top: 4px;
        right: 10px;
      }
      .navigation {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        .social {
          margin-top: 70px;
        }
        .tagline {
          margin-top: 30px;
          color: #FFFFFF;
          opacity: 0.35;
        }
        
        li {
          text-align: center;
        }

        a, button {
          white-space: nowrap;
          font-size: 2.4rem;
          font-weight: 600;
          margin: 1rem 0;
          display: inline-block;
          padding: 1rem 1rem;
          color: #FFFFFF;
        }
      }

    /* media */
    }

    button {
      background-color: transparent;
      color: #FFFFFF;
      cursor: pointer;
    }

    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: 1;
      justify-content: space-between;

      .logo {
          display: inline-block;
        img {
            max-height: 50px;
          }
      }
    span.circle-bg {
      display: none;
    }
    .navigation {
      display: grid;
      .toggle-menu {
        display: none;
      }
      li:hover a, li:hover button {
        text-decoration: underline;
      }
      li.talk-to-us button {
        font-weight: 600;
        border: 2px solid #FFFFFF;
        @media screen and (max-width: 1199px) {
          border: 2px solid #324B64;
        }
        border-radius: 50px;
        padding: 1rem 2rem;
        margin-left: 1rem;
        &:hover {
          text-decoration: none;
          background-color: #FFFFFF;
          color: #000000;
        }
      }
      li.social {
        display: none;
      }
      li.tagline {
        display: none;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(5, auto);
        align-content: center;

        a, button {
          display: inline-block;
          padding: 1rem 1rem;
          color: #FFFFFF;
          white-space: nowrap;
          @media screen and (max-width: 1199px) {
            color: #324B64;
          }
        }

      }
    }    
  /* media */
  }

  /* header */
  }

`
