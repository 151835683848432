import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { PaperformEmbed} from '../common/PaperformEmbed'
// import { Formik } from 'formik';


function TalkToUsForm({setIsTaskToUs}) {
  return (
    <FormWrapper>
      <div className="top">
      <button className="close" onClick={() => setIsTaskToUs(false)}><img src="/images/drawer-close.svg" alt="close drawer" /></button>
      </div>
      <PaperformEmbed formSlug={"pir2lead"}/>
    </FormWrapper>
  )
}


export default function TalkToUs({ isTaskToUs, setIsTaskToUs }) {
  return (
    <Wrapper>
      <Helmet>
        <body className={  isTaskToUs ? 'lock' : ''} />
      </Helmet>
      <div className={isTaskToUs ? 'overlay show' : 'overlay hidden'}></div>
      <div className={isTaskToUs ? 'drawer show' : 'drawer hidden'}><TalkToUsForm setIsTaskToUs={setIsTaskToUs}/></div>
    </Wrapper>
  )
}

const FormWrapper = styled.div`
  
  .top {
    padding: 2rem 2rem 1rem 2rem;
    position: relative;
    @media screen and (min-width: 768px) {
      padding: 4rem 4rem 2rem 4rem;
    }
    button.close {
      background-color: transparent;
      opacity: 0.8;
      cursor: pointer;
      position: absolute;
      top: 1rem;
      right: 2rem;
      transition: 0.5s;
      z-index: 9999;
      @media screen and (min-width: 768px) {
        top: 3rem;
      }
      img {
        max-width: 40px;
        @media screen and (min-width: 768px) {
          max-width: 60px;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  form {
    padding: 1rem 2rem 2rem 2rem;
    @media screen and (min-width: 768px) {
      padding: 2rem 4rem 4rem 4rem;
    }
  }
  h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 8px;
    @media screen and (min-width: 768px) {
    margin-bottom: 18px;
      font-size: 4rem;
    }
  }
  p {
    line-height: 160%;
  }
  .danger {
    margin-top: 11px;
    color:#C5283F;
    font-weight: 600;
    font-size: 12px;
  }
  hr {
    border: none;
    border-top: 1px solid #CCCCCC;
    width: 100%;
  }
  h2 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 8px;
    @media screen and (min-width: 768px) {
      font-size: 2.4rem;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 26px;
  }
  .input-group {
    margin-bottom: 18px;
    @media screen and (min-width: 768px) {
      margin-bottom: 32px;
    }
  }
  input[type="text"] {
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    outline: none;
    appearance: none;
    --webkit-appearance: none;
    font-size: 1.6rem;
    line-height: 50px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    font-family: 'AvenirNextWeb';
  }
  textarea {
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    outline: none;
    appearance: none;
    --webkit-appearance: none;
    font-size: 1.6rem;
    padding: 1rem;
    line-height: 28px;
    width: 100%;
    height: 160px;
    font-family: 'AvenirNextWeb';
  }
  
  .contact {
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }
  .input-group.checkbox {
    margin-right: 1rem;
    display: inline-block;
    input {
      display: none;
    }
    label {
      cursor: pointer;
      color: #111111;
      border: 1px solid #111111;
      display: block;
      line-height: 30px;
      padding: 0 1rem;
      border-radius: 50px;
    }
    input:checked ~ label {
      background-color: #111111;
      color: #FFFFFF;
    }
  }
  form button {
    font-weight: 600;
    color: #FFFFFF;
    background-color: #324B64;
    line-height: 56px;
    padding: 0 6rem;
    border-radius: 60px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background-color: #111111;
    }
  }
`

const Wrapper = styled.div`

.overlay {
  position: fixed;
  z-index: 9910;
  top: 0;
  left: 0;
  transition: 0.2s;
  width: 0%;
  height: 100%;
  &.hidden {
    background-color: rgba(0,0,0,0);
    width: 0%;
  }
  &.show {
    background-color: rgba(0,0,0,0.5);
    width: 100%;
  }
}

.drawer {
  position: fixed;
  overflow-y: auto;
  z-index: 9920;
  top: 0;
  transition: 0.2s;
  width: 100%;
  right: -100%;
  @media screen and (min-width: 768px) {
    width: 600px;
    right: -600px;
  }
  height: 100%;
  background-color: #FFFFFF;
  &.hidden {
    right: -100%;
    @media screen and (min-width: 768px) {
      right: -600px;
    }
  }
  &.show {
    right: 0%;
  }
}
`