import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PaperformEmbed } from '../common/PaperformEmbed'

const OpenPositionGroup = function ({setIsOpeningPosition}) {
  return (
    <div className="open-position-group">
      <button className="close" onClick={() => setIsOpeningPosition(false)}><img src="/images/drawer-close.svg" alt="close drawer" /></button>
      {/* <h1>Opening Positions</h1> */}
      <div className="listed">
        <PaperformEmbed formSlug={"jobatpir2"}/>
      </div>
      {/* <div className="text-center">
        <p className="desc">If any opening positions strive your mind in working with our team. Hurry up send us your story at:</p>
        <a href="mailto:job@pirsquare.net" className="email">job@pirsquare.net</a>
      </div> */}
    </div>
  )
}

const LifeAtPir = function () {
   var settings = {
     dots: true,
     infinite: true,
     autoplay: true,
     speed: 500,
     autoplaySpeed: 2500,
     cssEase: "ease",
     swipeToSlide: true,
  }
  return (
    <div className="life-at-pir">
        <h1>Life at Pi R SQUARE</h1>
        <Slider {...settings}>
          <div>
            <img src="/images/life-at-pir/1.jpg" alt="" />
          </div>
          <div>
            <img src="/images/life-at-pir/2.jpg" alt="" />
          </div>
          <div>
            <img src="/images/life-at-pir/3.jpg" alt="" />
          </div>
          <div>
            <img src="/images/life-at-pir/4.jpg" alt="" />
          </div>
          <div>
            <img src="/images/life-at-pir/5.jpg" alt="" />
          </div>
          <div>
            <img src="/images/life-at-pir/6.jpg" alt="" />
          </div>
        </Slider>
      </div>
  )
}

const OpenPositionContainer = function ({setIsOpeningPosition}) {
  return (
    <div className="open-position-container">
      <OpenPositionGroup setIsOpeningPosition={setIsOpeningPosition}/>
      <LifeAtPir />
    </div>
  )
}

export default function OpenPosition({isOpeningPosition, setIsOpeningPosition}) {
  return (
    <Wrapper className={isOpeningPosition ? 'show' : 'hidden'}>
      <Helmet>
        <body className={  isOpeningPosition ? 'lock' : ''} />
      </Helmet>
      <div className={isOpeningPosition ? 'overlay show' : 'overlay hidden'}></div>
      <div className={isOpeningPosition ? 'modal show' : 'modal hidden'}><OpenPositionContainer setIsOpeningPosition={setIsOpeningPosition}/></div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  z-index: 9900;
  position: fixed;
  top: 0;
  height: 0%;
  width: 100%;
  overflow-y: auto;
  &.show {
    height: 100%;
  }
  &.hidden {
    height: 0%;
  }
.overlay {
  position: fixed;
  z-index: 9910;
  bottom: 0;
  left: 0;
  transition: 1s;
  width: 100%;
  height: 0%;
  &.hidden {
    background-color: rgba(0,0,0,0);
    height: 0%;
  }
  &.show {
    background-color: rgba(0,0,0,0.5);
    height: 100%;
  }
}

.modal {
  z-index: 9920;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 900px;
  margin-top: -100%;
  transition: 0.7s;
  &.hidden {
    margin-top: -100%;
  }
  &.show {
    margin-top: 0%;
  }
  .open-position-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 5px 0px 0px rgba(135,204,230,1);
    -webkit-box-shadow: 0px 5px 0px 0px rgba(135,204,230,1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(135,204,230,1);
  }
  .open-position-group {
    position: relative;
    height: 100%;
    display: block;
    border-radius: 10px;
    color: #111111;
    padding: 2rem;
    @media screen and (min-width: 768px) {
      padding: 4rem;
    }
    h1 {
      font-size: 2.4rem;
      font-weight: 600;
      text-align: center;
      display: block;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      @media screen and (min-width: 768px) {
        font-size: 3rem;
      }
      margin-bottom: 20px;
    }
    .text-center {
      text-align: center;
    }
    .desc {
      text-align: center;
      margin-bottom: 10px;
      line-height: 160%;
    }
    a.email {
      display: inline-block;
      color: #111111;
      font-size: 20px;
      font-weight: 600;
      text-decoration: underline;
    }
    .listed {
      .item {
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid #CCCCCC;
        &:hover {
          border-bottom: 1px solid #87CCE6;
          h2 {
            color: #87CCE6;
          }
        }
      }

      .item .detail {
        transition: 0.65s;
        max-height: 0px;
        overflow: hidden;
        line-height: 160%; 
        ul {
          margin-left: 2rem;
        }
        li {
          list-style: disc;
        }
        strong, b {
          font-weight: 600;
        }
      }
      .item input {
        display: none;
      }
      .item input:checked ~ .detail {
        transition: 0.65s;
        max-height: 1000px;
      }
      .item .name span.material-icons {
        transform: rotate(0deg);
      }
      .item input:checked ~ .name span.material-icons {
        transform: rotate(180deg);
      }

      .name {
        display: grid;
        grid-template-columns: auto 30px;
        cursor: pointer;
        span.material-icons {
          text-align: center;
          padding-top: 2px;
          background-color: #87CCE6;
          border-radius: 50%;
          height: 27px;
          width: 27px;
          color: #FFFFFF;
          span {
            font-size: 27px;
          }
        }
      }
      h2 {
        font-weight: 600;
        line-height: 27px;
      }
    }
  }
  .life-at-pir {
    h1 {
      z-index: 9900;
      font-weight: 600;
      color: #FFFFFF;
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
      font-size: 2.4rem;
      @media screen and (min-width: 768px) {
        font-size: 3rem;
      }

    }
    background-color:#324B64;
    padding: 1rem 4rem 3rem 4rem;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    img {
      max-height: 200px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 6px;
      max-width: 100%;
    }
   }
  }
  button.close {
      z-index: 9999;
      background-color: transparent;
      opacity: 0.8;
      cursor: pointer;
      position: absolute;
      top: 1.8rem;
      right: 2rem;
      transition: 0.5s;
      @media screen and (min-width: 768px) {
        top: 2rem;
      }
      img {
        max-width: 30px;
        @media screen and (min-width: 768px) {
          max-width: 60px;
        }
      }
      &:hover {
        opacity: 1;
      }
    }
  
`