import React, { useContext } from 'react'
import styled from 'styled-components'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { AppState } from '../../data/State';
import CookieConsent from "react-cookie-consent";

export default function Footer() {
  const state = useContext(AppState)
  const { setIsOpeningPosition } = state

  return (
    <Wrapper>
      <footer>
        <CookieConsent
          buttonText="Allow Cookie"
          cookieName="PiRCookieConsent"
          style={{ background: "rgba(0,0,0,0.8)", fontWeight: 600, }}
        >🍪 We use cookies to imporve <br/>your browsing Experience. <u><a href="https://docs.google.com/document/d/e/2PACX-1vQp-3TfJlFJtbVAQpd45g52Q4vTErQ54fTMVvyUijT2Jkz9whQSu7LB62882A-2BnnX-xLqxuqkMYgm/pub" target="_blank">Read More</a></u></CookieConsent>
        <div className="container">
          
          <div className="about">
            <h1>Pi R SQUARE Co., Ltd.</h1>
            <p>Workstories 81/1 Sukhumvit 2, Khwaeng Khlong Toei, Khet Khlong Toei, Bangkok 10110</p>
          </div>
          <div className="ghost"></div>
          <div className="link">
            <h2>About Us</h2>
            <ul>
              <li>
                <AnchorLink to='/#about-us'>Mission</AnchorLink> 
              </li>
              <li>
                <AnchorLink to='/#what-we-do'>What we do</AnchorLink> 
              </li>
              <li>
                <button onClick={() => setIsOpeningPosition(true)}>Work with Us</button> <span class="red-highlight">We are Hiring!</span>
              </li>
            </ul>
          </div> 

          <div className="contact">
            <h2>Contact Pi R SQUARE</h2>
            <p>(+66) 92-424-0332</p>
            <p><a href="mailto:contact@pirsquare.net">contact@pirsquare.net</a></p>
            <p><a href="https://www.facebook.com/pir2thailand/" target="_blank"  rel="noreferrer" ><img src="/images/facebook.svg" alt="Facebook" /></a></p>
          </div>
            
        </div>
      </footer>
      <div className="copy">
        <div className="container">
          <div className="address">
            <p>© 2022 Pi R SQUARE Co., Ltd. All Rights Reserved. (Version 1.0)</p>
            <p><a href="/" target="_blank"  rel="noreferrer">Terms & conditions</a> ∙ <a href="/" target="_blank"  rel="noreferrer">Privacy policy</a></p>
          </div>
          <div>
            <p className="craft">Crafted with <span>❤︎</span> in Bangkok</p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #232323;
  footer {
    color: #B2B2B2;
    padding: 6rem 0 5rem 0;
    border-bottom: 1px solid #444444;
    .container {
      display: grid;
      grid-gap: 3rem;
      .ghost {
        display: none;
      }
      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media screen and (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .ghost {
          display: block;
        }
      }
    }
  }
  .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }
  h1 {
    font-size: 2.4rem;
    color: #FFFFFF;
    font-weight: 600;
    margin-bottom: 16px;
  }
  p {
    line-height: 180%;
  }
  a,button { color: inherit; background-color: transparent; }
  h2 {
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 2rem;
  }

  .link {
    display: none;
    @media screen and (min-width: 768px) {
      display: inline-block;
    }
    li {
      margin-bottom: 12px;
    }
  }
  .contact {
    p:last-child {
      margin-top: 1rem;
    }
  }
  .copy {
    color: #B2B2B2;
    font-size: 12px;
    padding: 1rem;
    .container {
      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
      }
      .address {
        
        @media screen and (min-width: 768px) {
          display: grid;
          grid-template-columns: auto auto;
        }
        grid-gap: 20px;
      }
      
      p.craft {
        @media screen and (min-width: 768px) {
          text-align: right;
          }
        span {
          color: #C5283F;
        }
      }
    }
  }
  .red-highlight {
    color: #FFFFFF;
    background: #C5283F;
    padding: 5px 10px;
    border-radius: 4px;
  }
  button {
    cursor: pointer;
  }
  .CookieConsent {
    z-index: 9999 !important;
    button {
      border-radius: 4px !important;
      background-color: #87CCE6 !important;
      color: #004762 !important;
    }
    small {
      padding-left: 23px;
      margin-top: 4px;
      font-size: 1.4rem;
      display: inline-block;
    }
    br {
        display: none;
      }
    @media screen and (max-width: 767px) {
      br {
        display: block;
      }
      text-align: center !important;
      div {
        width: 100% !important;
        text-align: center !important;
      }
      button {
        display: inline-block;
        margin: 10px !important;
        margin-top: 0 !important;
      }
    }
  }
`